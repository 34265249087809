import { FieldValues, FormProvider, useForm } from 'react-hook-form';

import { useEvent } from '@komo-tech/core/hooks/useEvent';

import { Group } from '../../Display/Group';
import { Form } from '../Form/Form';
import { FormHookField, IAmFormHookField } from '../FormHookField';
import { FormHookCountrySelect } from '../FormSelect/Country/FormHookCountrySelectV2';
import { FormHookTextInput } from '../FormTextInput';

interface Props<TFieldValues extends FieldValues = FieldValues>
  extends IAmFormHookField<TFieldValues> {}

interface AddressFormValues {
  subpremise: string;
  streetAddress: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
}

export function FormHookManualAddressV2<
  TFieldValues extends FieldValues = FieldValues
>({ hook }: Props<TFieldValues>) {
  const { field, required, disabled, setFieldValue } = hook;

  const methods = useForm<AddressFormValues>({
    mode: 'all',
    defaultValues: asJsonOrEmpty(field.value)
  });

  const handleFormChange = useEvent(() => {
    const values = methods.getValues();
    setFieldValue(JSON.stringify(values) as any);
  });

  return (
    <FormProvider {...methods}>
      <Form>
        <FormHookField<AddressFormValues> name="subpremise" disabled={disabled}>
          {(h) => (
            <FormHookTextInput
              hook={h}
              label={'Apartment, unit, suite, or floor #'}
              onChange={handleFormChange}
            />
          )}
        </FormHookField>
        <FormHookField<AddressFormValues>
          name="streetAddress"
          required={{ enabled: required }}
          disabled={disabled}
        >
          {(h) => (
            <FormHookTextInput
              hook={h}
              label={'Street address'}
              onChange={handleFormChange}
            />
          )}
        </FormHookField>
        <FormHookField<AddressFormValues>
          name="city"
          required={{ enabled: required }}
          disabled={disabled}
        >
          {(h) => (
            <FormHookTextInput
              hook={h}
              label={'City'}
              onChange={handleFormChange}
            />
          )}
        </FormHookField>
        <Group position={'apart'}>
          <FormHookField<AddressFormValues>
            name="state"
            required={{ enabled: required }}
            disabled={disabled}
          >
            {(h) => (
              <FormHookTextInput
                hook={h}
                label={'State/Province'}
                onChange={handleFormChange}
              />
            )}
          </FormHookField>
          <FormHookField<AddressFormValues>
            name="postal_code"
            required={{ enabled: required }}
            disabled={disabled}
          >
            {(h) => (
              <FormHookTextInput
                hook={h}
                label={'Zip/postal code'}
                onChange={handleFormChange}
              />
            )}
          </FormHookField>
        </Group>
        <FormHookField<AddressFormValues>
          name="country"
          required={{ enabled: required }}
          disabled={disabled}
        >
          {(h) => (
            <FormHookCountrySelect
              hook={h}
              dataVariant="default"
              label={'Country/region'}
              onChange={handleFormChange}
            />
          )}
        </FormHookField>
      </Form>
    </FormProvider>
  );
}

const asJsonOrEmpty = (value?: string) => {
  try {
    return value ? JSON.parse(value) : {};
  } catch {
    return {};
  }
};
