import { FieldValues } from 'react-hook-form';

import { IAmFormHookField } from '@/common/components/Form/FormHookField';
import { utcDateOrNull } from '@komo-tech/core/utils/date';

import {
  FormDateTimePicker,
  FormDateTimePickerV2Props
} from './FormDateTimePicker';

export interface FormHookDateTimePickerV2Props<
  TFieldValues extends FieldValues = FieldValues
> extends IAmFormHookField<TFieldValues>,
    Omit<
      FormDateTimePickerV2Props,
      'required' | 'name' | 'value' | 'defaultValue' | 'error' | 'disabled'
    > {}

export function FormHookDateTimePicker<
  TFieldValues extends FieldValues = FieldValues
>(props: FormHookDateTimePickerV2Props<TFieldValues>) {
  const { hook, onBlur, onChange, ...rest } = props;
  return (
    <FormDateTimePicker
      {...rest}
      required={hook.required}
      name={hook.field.name}
      ref={hook.field.ref}
      disabled={hook.disabled}
      onBlur={(e) => {
        hook.field.onBlur();
        onBlur?.(e);
      }}
      onChange={(e) => {
        hook.field.onChange(e);
        onChange?.(e);
      }}
      value={utcDateOrNull(hook.field.value)}
      error={hook.showError ? hook.errorMessage : undefined}
    />
  );
}
