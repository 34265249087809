import { MantineSize, Stack } from '@mantine/core';
import { FC, useState } from 'react';

import { FormPinInput } from '@/common/components/Form/FormPinInput/FormPinInput';
import { useToaster } from '@/common/components/Toast';
import { useActionHandler } from '@/common/hooks/useActionHandler';
import { useOnMount } from '@komo-tech/core/hooks/useOnMount';
import { Guid } from '@komo-tech/core/models/Guid';
import { Site } from '@/common/models/site/Site';
import { SiteUser } from '@/common/models/SiteUser';
import { DataDogServiceHelper } from '@/common/utils/DataDogService';
import { PublicAuthService } from '@/front/data/Auth';

import { AuthButton, resolveAuthButtonProps } from '../Shared';

interface Props {
  site: Site;
  onCancelSignIn: () => void;
  onSignInSuccess?: (user: SiteUser) => void;
  onSignInSuccessConfirm: (user: SiteUser) => void;
  email: string;
  greetingMessage: string;
  triggerFormId?: Guid;
  cancelText?: string;
  size?: MantineSize;
}

const resendSource = 'resend';
const signUpSource = 'sign-in';

export const SignInConfirm: FC<Props> = ({
  site,
  onCancelSignIn,
  onSignInSuccess,
  onSignInSuccessConfirm,
  greetingMessage,
  email,
  size,
  cancelText = 'Cancel sign-in'
}) => {
  const [codeHasError, setCodeHasError] = useState(false);
  const toaster = useToaster();
  const [code, setCode] = useState('');

  const [handleAsync, { isHandling, handlingSource }] = useActionHandler();

  //TODO HANDLE IF ANY ERRORS IN RESPONSE OF RESEND CODE
  const handleResendCodeAsync = () =>
    handleAsync(
      () =>
        PublicAuthService.actions.signInConfirmEmailAsync({
          siteId: site.id.toString(),
          email: email
        }),
      {
        source: resendSource
      }
    );

  useOnMount(() => {
    if (!site.properties.AuthMagicLinkEnabled) onCancelSignIn();
  });

  const handleSignInAsync = (code: string) =>
    handleAsync(
      () =>
        PublicAuthService.actions.signInVerifyAsync({
          code: code,
          email,
          siteId: site.id.toString()
        }),
      {
        onSuccess: ({ success, data: user }) => {
          if (success) {
            const loggedInInUser = new SiteUser(user);
            onSignInSuccess?.(loggedInInUser);
            onSignInSuccessConfirm(loggedInInUser);
            toaster.success(user.getGreetingMessage(greetingMessage));
            DataDogServiceHelper.trySetUserInformation(loggedInInUser);
          }
          if (!success) {
            setCode('');
            setCodeHasError(true);
          }
        },
        source: signUpSource
      }
    );

  return (
    <>
      <FormPinInput
        size="xxl"
        length={4}
        w="100%"
        type="number"
        oneTimeCode
        autoFocus
        disabled={isHandling}
        value={code}
        onChange={(v) => {
          setCode(v);
          setCodeHasError(false);
        }}
        error={codeHasError ? 'Please try again.' : undefined}
        onComplete={handleSignInAsync}
      />
      <Stack>
        <AuthButton
          w={'100%'}
          disabled={isHandling}
          busy={isHandling && handlingSource === resendSource}
          onClick={handleResendCodeAsync}
          size={size}
          label="Re-send code"
          {...resolveAuthButtonProps(site, 'primary')}
        />
        <AuthButton
          w={'100%'}
          disabled={isHandling}
          onClick={onCancelSignIn}
          size={size}
          label={cancelText}
          {...resolveAuthButtonProps(site, 'secondary')}
        />
      </Stack>
    </>
  );
};
