import { MantineSize } from '@mantine/core';
import React, { FC, useEffect, useState } from 'react';

import { SiteAuthState } from '@/common/components/Card/shared/Front/Routing/AuthSiteUrlFunctions';
import { DynamicFormHeaderSpacing } from '@/common/components/Form/DynamicForm/Renderer';
import { Text } from '@/common/components/Typography/Text';
import { Guid } from '@komo-tech/core/models/Guid';
import { Site } from '@/common/models/site/Site';
import { SiteUser } from '@/common/models/SiteUser';
import { resolveAuthButtonProps } from '@/front/components/auth/Shared';
import { SignUpForm } from '@/front/components/auth/SignUp';
import { SignUpConfirm } from '@/front/components/auth/SignUp/SignUpConfirm';
import { UserAccountsSignUpTransientData } from '@/front/components/shared/Competition/CompetitionForm/_UserAccounts/_SignUp';

interface Props {
  site: Site;
  state: SiteAuthState;
  onSignUpFormCheckSuccess: () => void;
  onSignUpSuccessConfirm: (user: SiteUser) => void;
  onCancel: () => void;
  triggerFormId?: Guid;
  size?: MantineSize;
}

export const AuthDrawerSignUp: FC<Props> = ({
  site,
  state,
  triggerFormId,
  onSignUpFormCheckSuccess,
  onSignUpSuccessConfirm,
  onCancel,
  size
}) => {
  const [transientData, setTransientData] =
    useState<UserAccountsSignUpTransientData>();

  useEffect(() => {
    if (state === SiteAuthState.SignUpConfirm && !transientData) {
      onCancel();
    }
  }, [state, transientData]);

  return (
    <>
      {state === SiteAuthState.SignUp && (
        <>
          <Text mt={0} mb={DynamicFormHeaderSpacing}>
            {site.properties.AuthSignUpFormSubText}
          </Text>
          <SignUpForm
            site={site}
            size={size}
            triggerFormId={triggerFormId}
            form={site.signUpForm}
            submitButton={resolveAuthButtonProps(site, 'primary')}
            onSuccess={() => {
              onSignUpFormCheckSuccess();
            }}
            extraButtons={[
              resolveAuthButtonProps(site, 'secondary', {
                label: 'Back to sign in',
                onClick: onCancel
              })
            ]}
            setTransientData={setTransientData}
          />
        </>
      )}
      {state === SiteAuthState.SignUpConfirm && (
        <SignUpConfirm
          site={site}
          onCancelSignUp={onCancel}
          triggerFormId={triggerFormId}
          onSignUpSuccessConfirm={onSignUpSuccessConfirm}
          data={transientData}
        />
      )}
    </>
  );
};
