import { CardDescriptor } from '@/common/models/CardDescriptor';
import { CompetitionCheck } from '@/common/models/competitions/CompetitionCheck';
import { CompetitionEntryApiRequest } from '@/common/models/competitions/CompetitionEntryApiRequest';
import { CompetitionEntryOptions } from '@/common/models/competitions/CompetitionEntryOptions';
import { CompetitionEntryReplayCheckResponse } from '@/common/models/competitions/CompetitionEntryReplayCheckResponse';
import { CompetitionEntryResult } from '@/common/models/competitions/CompetitionEntryResult';
import { Guid } from '@komo-tech/core/models/Guid';
import { Site } from '@/common/models/site/Site';
import { SiteUser } from '@/common/models/SiteUser';
import { DataDogServiceHelper } from '@/common/utils/DataDogService';
import { PublicSiteService } from '@/front/data/Sites';

import { IntegrationService } from './IntegrationService/IntegrationService';
import { PublicLiveApi } from './PublicApi';

interface SubmitOptions {
  cardDescriptor: CardDescriptor;
  site: Site;
  user: SiteUser;
  entryProps?: Record<string, unknown>;
  fields: Record<string, any>;
  onSuccessAsync?: (result: CompetitionEntryResult) => Promise<any>;
  onSettled?: () => void;
  onError?: (error: {
    response?: CompetitionEntryResult;
    unCaughtError?: any;
  }) => void;
  turnstileToken?: string;
}

export const PublicCompetitionService = {
  submitAsync: async ({
    onSuccessAsync,
    entryProps = {},
    fields,
    cardDescriptor,
    site,
    user,
    onError,
    onSettled,
    turnstileToken
  }: SubmitOptions) => {
    try {
      const request: CompetitionEntryApiRequest = {
        cardId: cardDescriptor.id,
        fields,
        ...entryProps,
        c: turnstileToken
      };

      const response = new CompetitionEntryResult(
        await PublicLiveApi.post<CompetitionEntryResult>(
          `sites/${site.id}/competitions/${cardDescriptor.competitionId}/entry`,
          request
        )
      );

      if (response.isError) {
        onError?.({ response });
        return response;
      }

      DataDogServiceHelper.trySetUserInformation(user, fields);
      await IntegrationService.staticTrackEventAsync(
        'form-submission',
        {
          scope: cardDescriptor.type,
          siteId: cardDescriptor.siteId,
          userId: user.userId,
          cardId: cardDescriptor.id,
          competitionId: cardDescriptor.competitionId,
          competitionEntryId: response.entryId,
          cardName: cardDescriptor.name
        },
        site
      );

      await onSuccessAsync?.(response);
      return response;
    } catch (e) {
      console.error(e);
      onError?.({ unCaughtError: e });
    } finally {
      onSettled?.();

      void PublicSiteService.actions.tryCollectActivityAsync(site.id);
    }
  },

  getEntryOptionsAsync: async (options: {
    siteId: string | Guid;
    competitionId: string | Guid;
  }) => {
    const { siteId, competitionId } = options;
    return new CompetitionEntryOptions(
      await PublicLiveApi.get(
        `sites/${siteId}/competitions/${competitionId}/entry-options`
      )
    );
  },

  checkEntryAsync: async (siteId: Guid, competitionId: Guid, userId: Guid) =>
    new CompetitionCheck(
      await PublicLiveApi.post(
        `sites/${siteId}/competitions/${competitionId}/check`,
        {
          userId
        }
      )
    ),

  hasEnteredAsync: async (competitionId: string | Guid) =>
    PublicLiveApi.get<boolean>(`competitions/${competitionId}/entry/status`),

  canReplayAsync: async (options: { competitionId: Guid; entryId: Guid }) =>
    new CompetitionEntryReplayCheckResponse(
      await PublicLiveApi.get(
        `competitions/${options.competitionId}/entries/${options.entryId}/can-replay`
      )
    )
};
