import { FrontAnyCard } from '@/common/models/AnyCard';
import { CardDescriptor } from '@/common/models/CardDescriptor';
import { FrontCardFactory } from '@/common/models/CardFactory';
import { CardTypes } from '@/common/models/CardTypes';
import { Guid } from '@komo-tech/core/models/Guid';

export class CardUpdatedEvent {
  cardId: Guid;
  cardDescriptor: CardDescriptor;
  typedCard: FrontAnyCard;

  get cardType(): CardTypes {
    return this.cardDescriptor.type;
  }

  get isPublished(): boolean {
    return this.cardDescriptor.isPublished;
  }

  constructor(props: Partial<CardUpdatedEvent>) {
    props = props || {};
    Object.assign(this, props);
    this.cardId = Guid.valueOrNew(props.cardId);
    this.cardDescriptor = new CardDescriptor(props.cardDescriptor);
    this.typedCard = FrontCardFactory.map(
      this.cardDescriptor.type,
      props.typedCard
    );
  }
}
