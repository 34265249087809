import { utcDateOrUndefined } from '@komo-tech/core/utils/date';

export class CompetitionEntryReplayCheckResponse {
  canReplay: boolean;
  isThrottled: boolean;
  lockedUntil?: Date;
  reason?: string;

  constructor(props?: Partial<CompetitionEntryReplayCheckResponse>) {
    props = props || {};
    Object.assign(this, props);
    this.lockedUntil = utcDateOrUndefined(props.lockedUntil);
  }
}
