import { Guid } from '@komo-tech/core/models/Guid';
import { MantineSize } from '@mantine/core';
import { FC, useState } from 'react';

import { CardHeader } from '@/common/components/Card/shared/CardHeader';
import { Stack } from '@/common/components/Display/Stack';
import { DynamicFormHeaderSpacing } from '@/common/components/Form/DynamicForm/Renderer';
import { FormPinInput } from '@/common/components/Form/FormPinInput/FormPinInput';
import { Text } from '@/common/components/Typography/Text';
import { useActionHandler } from '@/common/hooks/useActionHandler';
import { Site } from '@/common/models/site/Site';
import { SiteUser } from '@/common/models/SiteUser';
import { PublicAuthService } from '@/front/data/Auth';

import { UserAccountsSignUpTransientData } from '../../shared/Competition/CompetitionForm/_UserAccounts/_SignUp';
import { AuthButton, resolveAuthButtonProps } from '../Shared';

interface Props {
  site: Site;
  onCancelSignUp: () => void;
  onSignUpSuccess?: (user: SiteUser) => void;
  onSignUpSuccessConfirm: (user: SiteUser) => void;
  data: UserAccountsSignUpTransientData;
  triggerFormId?: Guid;
  cancelText?: string;
  size?: MantineSize;
}

const resendSource = 'resend';
const signUpSource = 'sign-up';

export const SignUpConfirm: FC<Props> = ({
  site,
  onCancelSignUp,
  onSignUpSuccess,
  onSignUpSuccessConfirm,
  data,
  triggerFormId,
  cancelText = 'Cancel sign-up',
  size
}) => {
  const [signedUpUser, setSignedUpUser] = useState<SiteUser | undefined>(
    undefined
  );
  const [code, setCode] = useState('');
  const [codeHasError, setCodeHasError] = useState(false);

  const [handleAsync, { isHandling, handlingSource }] = useActionHandler();

  //TODO HANDLE IF ANY ERRORS IN RESPONSE OF RESEND CODE
  const handleResendCodeAsync = () =>
    handleAsync(
      () =>
        PublicAuthService.actions.preSignUpAsync({
          siteId: site.id,
          email: data.email,
          fields: data.fields,
          triggerFormId
        }),
      {
        source: resendSource
      }
    );

  const handleSignUpAsync = (code: string) =>
    handleAsync(
      () =>
        PublicAuthService.actions.signUpAsync({
          siteId: site.id,
          code: code,
          email: data.email,
          password: data.password,
          triggerFormId,
          fields: data.fields
        }),
      {
        onSuccess: ({ success, data: user }) => {
          if (success) {
            const loggedInInUser = new SiteUser(user);
            onSignUpSuccess?.(loggedInInUser);
            setSignedUpUser(loggedInInUser);
          }
          if (!success) {
            setCode('');
            setCodeHasError(true);
          }
        },
        source: signUpSource
      }
    );

  return (
    <>
      {signedUpUser && (
        <>
          <Stack mt="md" gap={DynamicFormHeaderSpacing}>
            <Stack gap="0.5rem">
              <CardHeader variant="h3">
                {site.properties.AuthSignUpHeader}
              </CardHeader>
              <Text my={0}>{site.properties.AuthSignUpSubText}</Text>
            </Stack>

            <AuthButton
              {...resolveAuthButtonProps(site, 'primary')}
              label="Close"
              w={'100%'}
              size={size}
              onClick={() => onSignUpSuccessConfirm(signedUpUser)}
            />
          </Stack>
        </>
      )}
      {!signedUpUser && (
        <>
          <Stack mt="md" gap={DynamicFormHeaderSpacing}>
            <Stack gap="0.5rem">
              <CardHeader>{site.properties.AuthVerificationHeader}</CardHeader>
              <Text my={0}>{site.properties.AuthVerificationSubText}</Text>
            </Stack>
            <FormPinInput
              size="xxl"
              length={4}
              w="100%"
              type="number"
              oneTimeCode
              autoFocus
              disabled={isHandling}
              value={code}
              onChange={(v) => {
                setCode(v);
                setCodeHasError(false);
              }}
              error={
                codeHasError
                  ? 'Code was incorrect. Please try again.'
                  : undefined
              }
              onComplete={handleSignUpAsync}
            />
            <Stack>
              <AuthButton
                {...resolveAuthButtonProps(site, 'primary')}
                w={'100%'}
                disabled={isHandling}
                busy={isHandling && handlingSource === resendSource}
                label="Re-send code"
                size={size}
                onClick={handleResendCodeAsync}
              />
              <AuthButton
                {...resolveAuthButtonProps(site, 'secondary')}
                w={'100%'}
                disabled={isHandling}
                label={cancelText}
                size={size}
                onClick={onCancelSignUp}
              />
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
};
