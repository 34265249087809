import {
  __InputWrapperProps,
  Input as MantineInput,
  MantineSize
} from '@mantine/core';
import isNil from 'lodash/isNil';
import React, { useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

import { Button } from '@/common/components/Button';
import {
  FileDynamicFieldOptions,
  IAmDynamicFormFieldProps
} from '@/common/components/Form/DynamicForm';
import {
  FormHookField,
  IAmFormHookField
} from '@/common/components/Form/FormHookField';
import {
  FormInputWrapper,
  IAmFormInputRenderProps
} from '@/common/components/Form/InputWrapper';
import { FileTextIcon } from '@/common/components/Icons/FileTextIcon';
import { ReceiptTextIcon } from '@/common/components/Icons/ReceiptTextIcon';
import { UploadIcon } from '@/common/components/Icons/UploadIcon';
import { ListDeleteRenderer } from '@/common/components/List/ListRenderer';
import { useReceiptUploaderFormField } from '@/common/components/ReceiptValidation/useReceiptUploaderFormField';
import { useEvent } from '@komo-tech/core/hooks/useEvent';
import { useOnMount } from '@komo-tech/core/hooks/useOnMount';
import { Guid } from '@komo-tech/core/models/Guid';
import { FrontReceiptValidatorDefinition } from '@/common/models/receipt-validator-definition/Front/FrontReceiptValidatorDefinition';
import { FormFileAllowedType } from '@komo-tech/core/utils/file';

import { formFieldToDynamicField } from '../DynamicFormRendererField';
import { useUploadReceiptModal } from './_useUploadReceiptModal';

type ReceiptUploadFieldProps = {
  definition?: FrontReceiptValidatorDefinition;
  formId: Guid;
  disabled?: boolean;
  size?: MantineSize;
};

export const OptionalReceiptUploadField = ({
  definition,
  formId,
  disabled,
  size
}: ReceiptUploadFieldProps) => {
  const field = useReceiptUploaderFormField({
    enabled: !!definition,
    formId,
    label: definition?.label
  });

  const dynamicField = useMemo(
    () => (!!field ? formFieldToDynamicField({ formField: field }) : null),
    [field]
  );

  if (!dynamicField) {
    return null;
  }

  return (
    <_ReceiptUploadField field={dynamicField} disabled={disabled} size={size} />
  );
};

function _ReceiptUploadField<
  TFieldValues extends FieldValues = FieldValues,
  TContextData = unknown
>({
  field,
  disabled,
  ...rest
}: IAmDynamicFormFieldProps<TFieldValues, TContextData>) {
  const { name, validators: validatorsProp = [], required } = field;

  const validators = [...validatorsProp];
  if (required) {
    validators.push((v: string) => {
      if (!v) return undefined; //Handled by the default required validator
      if (!resolveFormFileInputValue(v)?.length) {
        return 'Field is required';
      }
      return undefined;
    });
  }

  const { fileLimit, ...restOptions } = field.data
    .options as FileDynamicFieldOptions;
  if (fileLimit > 0) {
    validators.push((v: string) => {
      if (resolveFormFileInputValue(v)?.length > fileLimit) {
        return `Only ${fileLimit.toLocaleString()} file(s) can be uploaded`;
      }
      return undefined;
    });
  }

  return (
    <FormHookField<TFieldValues>
      name={name}
      disabled={disabled || field.disabled}
      validators={validators}
      required={{ enabled: required }}
    >
      {(h) => (
        <FormHookReceiptUploadInput
          hook={h}
          {...(field.data.options as FileDynamicFieldOptions)}
          {...rest}
          fileLimit={fileLimit}
          {...restOptions}
        />
      )}
    </FormHookField>
  );
}

interface FormHookReceiptUploadInputProps<
  TFieldValues extends FieldValues = FieldValues
> extends IAmFormHookField<TFieldValues>,
    Omit<
      ReceiptUploadInputProps,
      | 'required'
      | 'name'
      | 'value'
      | 'defaultValue'
      | 'error'
      | 'disabled'
      | 'onDelete'
    > {}

function FormHookReceiptUploadInput<
  TFieldValues extends FieldValues = FieldValues
>({
  hook,
  onUploadAsync,
  ...rest
}: FormHookReceiptUploadInputProps<TFieldValues>) {
  return (
    <ReceiptUploadInput
      {...rest}
      required={hook.required}
      name={hook.field.name}
      ref={hook.field.ref}
      disabled={hook.disabled}
      onUploadAsync={async (f) => {
        const files = await onUploadAsync(f);
        hook.setFieldValue(
          JSON.stringify([
            ...resolveFormFileInputValue(hook.field.value),
            ...files
          ]) as any,
          {
            shouldValidate: true,
            shouldDirty: true
          }
        );
        return files;
      }}
      value={!isNil(hook.field.value) ? hook.field.value : ''}
      error={hook.showError ? hook.errorMessage : undefined}
      onDelete={(filePath) => {
        hook.setFieldValue(
          JSON.stringify(
            [...resolveFormFileInputValue(hook.field.value)].filter(
              (f) => f !== filePath
            )
          ) as any,
          {
            shouldValidate: true,
            shouldDirty: true
          }
        );
      }}
    />
  );
}

interface ReceiptUploadInputProps
  extends Omit<__InputWrapperProps, 'labelProps' | 'descriptionProps'>,
    Omit<IAmFormInputRenderProps<HTMLButtonElement>, 'selectAllTextOnFocus'> {
  /** Input element type */
  value?: string;
  onDelete: (file: string) => void;
  onUploadAsync: (files: File[]) => Promise<string[]>;
  id?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  fileLimit?: number;
  autoPick?: boolean;
  allowedFileTypes?: FormFileAllowedType[];
}

const ReceiptUploadInput = React.forwardRef<
  HTMLButtonElement,
  ReceiptUploadInputProps
>(
  (
    {
      value,
      size,
      required,
      onFocus,
      autoFocus,
      inputContainer,
      label,
      labelProps,
      description,
      descriptionProps,
      error,
      hideErrorMessage,
      disabled,
      allowedFileTypes,
      onDelete,
      onUploadAsync,
      placeholder = 'Upload receipt',
      autoPick = false,
      fileLimit,
      ...rest
    },
    ref
  ) => {
    const files = useMemo(() => resolveFormFileInputValue(value), [value]);
    const hasFiles = files?.length > 0;
    const hasFileLimit = fileLimit > 0;
    const hasSingleFile = files?.length === 1;

    const handleUploadAsync = useEvent((fileList?: File[]) => {
      if (!fileList?.length) {
        return Promise.resolve([] as string[]);
      }
      let files = Array.from(fileList);
      if (hasFileLimit) {
        files = files.slice(0, fileLimit);
      }
      return onUploadAsync(files);
    });

    const uploadReceiptModal = useUploadReceiptModal({
      allowedFileTypes,
      onUploadAsync: handleUploadAsync
    });

    useOnMount(() => {
      if (autoPick) {
        uploadReceiptModal.show();
      }
    });

    const hasError = !!error;
    const hasReachedLimit = hasFileLimit && (files || []).length >= fileLimit;
    return (
      <FormInputWrapper<HTMLButtonElement>
        ref={ref}
        autoFocus={autoFocus}
        onFocus={onFocus}
        labelProps={labelProps}
        inputContainer={inputContainer}
        descriptionProps={descriptionProps}
        error={error}
        size={size}
        hideErrorMessage={hideErrorMessage}
      >
        {(p, r) => (
          <MantineInput.Wrapper
            withAsterisk={required}
            label={label}
            description={description}
            {...(p as any)}
            {...rest}
          >
            {hasFiles && (
              <ListDeleteRenderer
                itemStyles={{ textColorVariant: 'green.9' }}
                items={files.map((f, i) => ({
                  id: f,
                  text: `Receipt ${hasSingleFile ? '' : i + 1}`,
                  icon: <ReceiptTextIcon />
                }))}
                onDelete={({ id }) => onDelete(id)}
              />
            )}
            <Button
              display={hasReachedLimit ? 'none' : 'block'}
              variant="default"
              w="100%"
              disabled={disabled || (hasFileLimit && files.length >= fileLimit)}
              size={size}
              style={
                hasError
                  ? {
                      borderColor: 'var(--komo-color-error)',
                      color: 'var(--komo-color-error)'
                    }
                  : undefined
              }
              onClick={() => uploadReceiptModal.show()}
              leftSection={<UploadIcon />}
              ref={r}
            >
              {placeholder}
            </Button>
          </MantineInput.Wrapper>
        )}
      </FormInputWrapper>
    );
  }
);

const resolveFormFileInputValue = (value?: string) => {
  try {
    return value ? (JSON.parse(value) as string[]).filter((x) => !!x) : [];
  } catch {
    return [];
  }
};
