import { FieldValues } from 'react-hook-form';

import { IAmFormHookField } from '@/common/components/Form/FormHookField';
import { utcDateOrUndefined } from '@komo-tech/core/utils/date';

import { FormDateInputV2, FormDateInputV2Props } from './FormDateInputV2';

export interface FormHookDateInputV2Props<
  TFieldValues extends FieldValues = FieldValues
> extends IAmFormHookField<TFieldValues>,
    Omit<
      FormDateInputV2Props,
      'required' | 'name' | 'value' | 'defaultValue' | 'error' | 'disabled'
    > {}

export function FormHookDateInputV2<
  TFieldValues extends FieldValues = FieldValues
>(props: FormHookDateInputV2Props<TFieldValues>) {
  const { hook, onBlur, onChange, ...rest } = props;

  return (
    <FormDateInputV2
      {...rest}
      required={hook.required}
      name={hook.field.name}
      ref={hook.field.ref}
      disabled={hook.disabled}
      onBlur={(e) => {
        hook.field.onBlur();
        onBlur?.(e);
      }}
      onChange={(e) => {
        hook.field.onChange(e);
        onChange?.(e);
      }}
      value={utcDateOrUndefined(hook.field.value)}
      error={hook.showError ? hook.errorMessage : undefined}
    />
  );
}
