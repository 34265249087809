import {
  forwardRef,
  MutableRefObject,
  ReactNode,
  useMemo,
  useRef
} from 'react';
import { animated, config, useSpring } from 'react-spring';

import { useEvent } from '@komo-tech/core/hooks/useEvent';
import { useOnMount } from '@komo-tech/core/hooks/useOnMount';
import { useWindowSize } from '@komo-tech/core/hooks/useWindowSize';

import classes from './CircleWipeContainer.module.css';

interface Props {
  bgColor?: string;
  onRest?: () => void;
  fireOnInit?: boolean;
  children?: ReactNode;
}

export interface CircleWipeContainerModel {
  toggle: () => void;
}

export const CircleWipeContainer = forwardRef<CircleWipeContainerModel, Props>(
  (
    { bgColor = 'white', onRest, children, fireOnInit },
    ref: MutableRefObject<CircleWipeContainerModel>
  ) => {
    const isExpandedRef = useRef(false);

    const [style, api] = useSpring(() => ({
      scale: 0,
      config: config.default
    }));

    const handleToggle = useEvent(() => {
      api.start(() => {
        const isExpanded = isExpandedRef.current;
        isExpandedRef.current = !isExpanded;
        return {
          scale: isExpanded ? 0 : 1,
          onRest
        };
      });
    });

    useOnMount(() => {
      if (fireOnInit) {
        handleToggle();
      }
      if (ref) {
        ref.current = {
          toggle: handleToggle
        };
      }
    });

    const { height, width } = useWindowSize();
    const size = useMemo(() => {
      const hw = width / 2;
      const hh = height / 2;
      const x = Math.sqrt(hw * hw + hh * hh) * 2;
      return `${x}px`;
    }, [height, width]);

    return (
      <>
        <animated.div
          style={{
            ...style,
            backgroundColor: bgColor,
            width: size,
            height: size
          }}
          className={classes.circle}
        >
          {children}
        </animated.div>
      </>
    );
  }
);
