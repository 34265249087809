import {
  __InputWrapperProps as MantineInputWrapperBaseProps,
  Input as MantineInput,
  MantineStyleProps as MantineDefaultProps
} from '@mantine/core';
import { Property } from 'csstype';
import { FC } from 'react';

import { Box } from '@/common/components/Display/Box';
import { ElementHighlighter } from '@/common/components/ElementHighlighter';
import { IconProps } from '@/common/components/Icons/IconProps';
import { ImageAddIcon } from '@/common/components/Icons/ImageAddIcon';
import { Image } from '@/common/components/Image';
import {
  useImageEditorModal,
  UseImageModalOptions
} from '@/common/components/Image/Editor/Modal';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';

import {
  FormInputWrapper,
  IAmFormInputRenderProps
} from '../InputWrapper/FormInputWrapper';
import classes from './FormImageEditorV2.module.css';

export interface FormImageEditorV2Props
  extends MantineDefaultProps,
    Omit<
      MantineInputWrapperBaseProps,
      'labelProps' | 'descriptionProps' | 'withAsterisk'
    >,
    Omit<
      IAmFormInputRenderProps<HTMLDivElement>,
      'autoFocus' | 'onFocus' | 'selectAllTextOnFocus'
    >,
    Pick<
      UseImageModalOptions,
      'acceptTypes' | 'onSignAsync' | 'onChange' | 'hiddenTabs' | 'canRemove'
    > {
  disabled?: boolean;
  imageSize?: UseImageModalOptions['size'];
  value?: ImageDataModel;
  objectFit?: Property.ObjectFit;
  forceSize?: string | number;
  placeholderIconSize?: IconProps['size'];
}

export const FormImageEditorV2: FC<FormImageEditorV2Props> = ({
  description,
  descriptionProps,
  inputContainer,
  labelProps,
  showSkeleton,
  label,
  imageSize,
  required,
  error,
  hideErrorMessage,
  disabled,
  acceptTypes,
  size,
  value,
  objectFit = 'cover',
  onChange,
  onSignAsync,
  canRemove,
  hiddenTabs,
  forceSize,
  placeholderIconSize = 'xl',
  ...rest
}) => {
  const imageModal = useImageEditorModal({
    onChange,
    onSignAsync,
    canRemove,
    hiddenTabs,
    acceptTypes,
    size: imageSize,
    disabled
  });

  return (
    <FormInputWrapper<HTMLDivElement>
      size={size}
      inputContainer={inputContainer}
      labelProps={labelProps}
      descriptionProps={descriptionProps}
      showSkeleton={showSkeleton}
      error={error}
      hideErrorMessage={hideErrorMessage}
    >
      {(p) => (
        <MantineInput.Wrapper
          withAsterisk={required}
          label={label}
          description={description}
          descriptionProps={descriptionProps}
          error={error}
          {...p}
          {...rest}
        >
          <Box
            flex="1 1 auto"
            miw="6rem"
            mih="6rem"
            w={forceSize}
            h={forceSize}
          >
            <ElementHighlighter
              tooltip={!value?.url ? 'Add image' : 'Edit image'}
              disabled={disabled}
              onClick={() => imageModal.show(value)}
              w="100%"
              h="100%"
            >
              {value?.url && (
                <>
                  <div className={classes.imageWrapper}>
                    <Image image={value} objectFit={objectFit} />
                  </div>
                </>
              )}
              {!value?.url && (
                <div className={classes.placeholder}>
                  <ImageAddIcon size={placeholderIconSize} />
                </div>
              )}
            </ElementHighlighter>
          </Box>
        </MantineInput.Wrapper>
      )}
    </FormInputWrapper>
  );
};
