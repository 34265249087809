import { ValidationRegex } from '@komo-tech/core/utils/regex';
import { MantineSize } from '@mantine/core';
import React, { FC } from 'react';

import { Button } from '@/common/components/Button';
import { CardHeader } from '@/common/components/Card/shared/CardHeader';
import { Stack } from '@/common/components/Display/Stack';
import { FormHook } from '@/common/components/Form/Form';
import { FormHookField } from '@/common/components/Form/FormHookField';
import { FormHookEmailInput } from '@/common/components/Form/FormTextInput/FormHookEmailInput';
import { ArrowRightIcon } from '@/common/components/Icons/ArrowRightIcon';
import { Text } from '@/common/components/Typography/Text';
import { useFlag } from '@komo-tech/core/hooks/useFlag';
import { Site } from '@/common/models/site/Site';
import { VerifyDrawerContent } from '@/front/components/shared/Competition/CompetitionForm/_ContactVerification/VerifyDrawerContent';
import { PublicContactsService } from '@/front/data/Contacts';

import { useUserContextSelector } from '../../../UserContext';

interface Props {
  site: Site;
  onVerifySuccess: () => void;
  onCancel: () => void;
  size?: MantineSize;
  email?: string;
}

interface FormValues {
  email: string;
}

export const AuthDrawerVerify: FC<Props> = ({
  site,
  onVerifySuccess,
  onCancel,
  size,
  email = ''
}) => {
  const setUser = useUserContextSelector((s) => s.setUser);

  const showField = useFlag(false);

  const [checkAsync, isHandling] =
    PublicContactsService.hooks.useEmailCheckHandle({});

  const handleSubmit = (email: string) => {
    showField.setTrue();
    checkAsync({ email, forceVerify: true, sendVerifyEmail: true });
  };

  const signIn = site.properties.ContactVerifySignIn;

  return (
    <FormHook<FormValues>
      defaultValues={{ email: email }}
      onSubmit={(v) => handleSubmit(v.email)}
    >
      {!showField.value ? (
        <>
          <Stack gap="0.5rem">
            <CardHeader variant="h3">{signIn.header || 'Welcome!'}</CardHeader>
            <Text my={0}>
              {signIn.subHeader || 'Save time entering competitions'}
            </Text>
          </Stack>
          <FormHookField
            name="email"
            required={{ enabled: true, trim: true }}
            contextData={{ isHandling }}
            validators={[
              (v: string, _, { isHandling }) => {
                //Don't show error if in process of checking
                if (isHandling) return null;
                //Skip required validation as it's handled by a different validator
                if (!(v || '').trim()) {
                  return null;
                }

                const emailValue = v.toLowerCase().trim();
                if (!ValidationRegex.email.test(emailValue)) {
                  return 'Not a valid email';
                }

                return null;
              }
            ]}
          >
            {(h) => (
              <FormHookEmailInput
                hook={h}
                label="Email"
                size={size}
                readOnly={isHandling}
                hideErrorMessage={isHandling}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit(e.currentTarget.value);
                    e.stopPropagation();
                    e.currentTarget.blur();
                  }
                  if (e.key === 'Tab') {
                    e.currentTarget.blur();
                  }
                }}
              />
            )}
          </FormHookField>
          <Button
            variant="styled"
            w="100%"
            type={'submit'}
            styledVariantProps={{
              color: signIn.signInButton.color,
              bgColor: signIn.signInButton?.backgroundColor,
              borderColor: signIn.signInButton?.borderColor
            }}
            rightSection={<ArrowRightIcon />}
          >
            Next
          </Button>
          <Button
            variant="styled"
            w="100%"
            styledVariantProps={{
              color: signIn.cancelButton.color,
              bgColor: signIn.cancelButton?.backgroundColor,
              borderColor: signIn.cancelButton?.borderColor
            }}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </>
      ) : (
        <VerifyDrawerContent
          site={site}
          onUserChange={setUser}
          onVerifySuccess={onVerifySuccess}
          onCancel={onCancel}
        />
      )}
    </FormHook>
  );
};
