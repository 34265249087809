import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { Guid } from '@komo-tech/core/models/Guid';
import { SiteMenu } from '@/common/models/site-menu/SiteMenu';

import { PublicSiteMenuActions as Actions } from '../_actions';
import { PublicSiteMenuCacheKeys as CacheKeys } from '../_cacheKeys';

export const useGetSiteMenu = (
  siteMenuId: Guid,
  options: Partial<UseQueryOptions<SiteMenu>> = {}
) => {
  const query = useQuery(
    CacheKeys.get(siteMenuId),
    () => Actions.getAsync(siteMenuId),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );

  const isLoading = query.isLoading || query.isInitialLoading;
  return [query.data, isLoading] as const;
};
