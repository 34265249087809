import { useOnMount } from '@komo-tech/core/hooks/useOnMount';
import { SessionStorageHelper } from '@komo-tech/core/utils/sessionStorage';
import orderBy from 'lodash/orderBy';
import { useRouter } from 'next/router';

type Options = {
  key?: string;
};

export const usePrefillFormValuesFromRoute = (options?: Options) => {
  const router = useRouter();
  useOnMount(() => {
    const queryParams = Object.entries(router.query);

    if (queryParams.length == 0) {
      return;
    }

    const keyPrefixes: string[] = ['komo_f:'];
    if (options?.key) {
      keyPrefixes.push(options.key);
    }
    const newQueryParams = {};
    let hasRemovedParams = false;
    queryParams.forEach((p) => {
      const key = p[0];
      const value = p[1] as string;
      const [hasMatch, prefix] = hasPrefix(key, keyPrefixes);
      if (hasMatch) {
        hasRemovedParams = true;
        SessionStorageHelper.setItem(key.substring(prefix.length), value);
      } else {
        newQueryParams[key] = value;
      }
    });

    // don't reroute if we don't need to
    if (!hasRemovedParams) {
      return;
    }

    router.replace(
      { pathname: router.pathname, query: newQueryParams },
      undefined,
      { shallow: true }
    );
  });
};

const hasPrefix = (key: string, prefixes: string[]) => {
  const match = orderBy(prefixes, 'length', 'desc').find((p) =>
    key.startsWith(p)
  );
  if (match) {
    return [true, match] as const;
  }

  return [false, null] as const;
};
