import { Guid } from '@komo-tech/core/models/Guid';
import { mapArray } from '@komo-tech/core/utils/array';
import { utcDateOrUndefined } from '@komo-tech/core/utils/date';

import { CompetitionCheckCodes } from './CompetitionCheckCodes';

export class CompetitionCheck {
  code: CompetitionCheckCodes = CompetitionCheckCodes.CannotEnter;
  reason: string;
  completed?: CompetitionCheckCompletedPlay;
  requiredFields: string[];
  retryAfter?: Date;
  existingRegistrationEntryId?: Guid;

  constructor(props?: Partial<CompetitionCheck>) {
    props = props || {};
    Object.assign(this, props);
    this.requiredFields = mapArray(props.requiredFields, (x) => x);
    this.retryAfter = utcDateOrUndefined(props.retryAfter);
    if (props.completed) {
      this.completed = new CompetitionCheckCompletedPlay(props.completed);
    }
    this.existingRegistrationEntryId = Guid.valueOrUndefined(
      props.existingRegistrationEntryId
    );
  }
}

export class CompetitionCheckCompletedPlay {
  id: Guid;
  guidValues?: Guid[];
  numberValue?: number;
  competitionEntryId?: Guid;

  constructor(props?: Partial<CompetitionCheckCompletedPlay>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrUndefined(props.id);
    this.competitionEntryId = Guid.valueOrUndefined(props.competitionEntryId);
    this.guidValues = !props.guidValues?.length
      ? []
      : props.guidValues.map((x) => Guid.valueOrUndefined(x));
  }
}
