import { __InputWrapperProps, Input as MantineInput } from '@mantine/core';
import React, {
  CSSProperties,
  InputHTMLAttributes,
  useMemo,
  useRef
} from 'react';

import { Button } from '@/common/components/Button';
import { FileTextIcon } from '@/common/components/Icons/FileTextIcon';
import { useActionHandler } from '@/common/hooks/useActionHandler';
import { useOnMount } from '@komo-tech/core/hooks/useOnMount';

import { ListDeleteRenderer } from '../../List/ListRenderer';
import {
  FormInputWrapper,
  IAmFormInputRenderProps
} from '../InputWrapper/FormInputWrapper';

export interface FormFileInputV2Props
  extends Omit<__InputWrapperProps, 'labelProps' | 'descriptionProps'>,
    Omit<IAmFormInputRenderProps<HTMLButtonElement>, 'selectAllTextOnFocus'> {
  /** Input element type */
  value?: string;
  onDelete: (file: string) => void;
  onUploadAsync: (files: File[]) => Promise<string[]>;
  id?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  fileLimit?: number;
  accept?: InputHTMLAttributes<HTMLInputElement>['accept'];
  autoPick?: boolean;
}

export const FormFileInputV2 = React.forwardRef<
  HTMLButtonElement,
  FormFileInputV2Props
>(
  (
    {
      value,
      size,
      required,
      onFocus,
      autoFocus,
      inputContainer,
      label,
      labelProps,
      description,
      descriptionProps,
      error,
      hideErrorMessage,
      disabled,
      accept = 'image/*',
      onDelete,
      onUploadAsync,
      id,
      name,
      placeholder = 'Upload file',
      autoPick = false,
      fileLimit,
      ...rest
    },
    ref
  ) => {
    useOnMount(() => {
      if (autoPick) {
        inputRef.current?.click?.();
      }
    });

    const [handleAsync, { isHandling }] = useActionHandler();
    const inputRef = useRef<HTMLInputElement>();

    const files = useMemo(() => resolveFormFileInputValue(value), [value]);
    const hasFiles = files?.length > 0;
    const hasFileLimit = fileLimit > 0;

    return (
      <FormInputWrapper<HTMLButtonElement>
        ref={ref}
        autoFocus={autoFocus}
        onFocus={onFocus}
        labelProps={labelProps}
        inputContainer={inputContainer}
        descriptionProps={descriptionProps}
        error={error}
        size={size}
        hideErrorMessage={hideErrorMessage}
      >
        {(p, r) => (
          <MantineInput.Wrapper
            withAsterisk={required}
            label={label}
            description={description}
            {...(p as any)}
            {...rest}
          >
            {hasFiles && (
              <ListDeleteRenderer
                itemStyles={{ textColorVariant: 'green.9' }}
                items={files.map((f, i) => ({
                  id: f,
                  text: `File ${i + 1}`,
                  icon: <FileTextIcon />
                }))}
                onDelete={({ id }) => onDelete(id)}
              />
            )}

            <Button
              display="block"
              variant="default"
              w="100%"
              disabled={disabled || (hasFileLimit && files.length >= fileLimit)}
              size={size}
              busy={isHandling}
              style={
                !!error
                  ? {
                      borderColor: 'var(--mantine-color-red-7)',
                      color: 'var(--mantine-color-red-7)'
                    }
                  : undefined
              }
              onClick={() => inputRef.current?.click?.()}
              ref={r}
            >
              <input
                id={id}
                name={name as string}
                type="file"
                accept={accept}
                multiple={!hasFileLimit || fileLimit > 1}
                disabled={disabled}
                onChange={(e) => {
                  const fileList = e.currentTarget.files;
                  if (!fileList?.length) {
                    return;
                  }
                  handleAsync(() => {
                    let files = Array.from(fileList);
                    if (hasFileLimit) {
                      files = files.slice(0, fileLimit);
                    }
                    return onUploadAsync(files);
                  });
                }}
                style={inputStyle}
                ref={inputRef}
              />
              {placeholder}
            </Button>
          </MantineInput.Wrapper>
        )}
      </FormInputWrapper>
    );
  }
);

export const resolveFormFileInputValue = (value?: string) => {
  try {
    return value ? (JSON.parse(value) as string[]).filter((x) => !!x) : [];
  } catch {
    return [];
  }
};

const inputStyle: CSSProperties = { display: 'none' };
