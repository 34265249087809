import { FC, useMemo } from 'react';

import { Group } from '@/common/components/Display/Group';
import { Stack } from '@/common/components/Display/Stack';
import { Fieldset } from '@/common/components/Fieldset';
import { IAmDynamicFormFieldProps } from '@/common/components/Form/DynamicForm';
import {
  FormHookField,
  IAmFormHookField
} from '@/common/components/Form/FormHookField';
import { useEvent } from '@komo-tech/core/hooks/useEvent';
import { FormField } from '@/common/models/form/FormField';
import { AddressFieldConfig } from '@/common/models/form/FormFieldConfig';
import { KeyOf } from '@komo-tech/core/models/path';

import { FormInputLabel } from '../../../InputWrapper/FormInputLabel';
import { CityField } from './_City';
import { CountryField } from './_Country';
import { PostCodeField } from './_PostCode';
import { StateField } from './_State';
import { StreetAddressField } from './_StreetAddress';
import { SubPremiseField } from './_SubPremise';
import classes from './DynamicFieldAddress.module.css';
import { DynamicFormAddress } from './DynamicFieldAddress.types';

interface Props extends IAmDynamicFormFieldProps {
  formField: FormField;
}
export const DynamicFieldAddress: FC<Props> = ({
  field,
  size,
  disabled,
  formField
}) => {
  return (
    <FormHookField
      name={field.name}
      disabled={disabled || field.disabled}
      validators={field.validators}
      required={{ enabled: field.required }}
    >
      {(h) => <_Field hook={h} formField={formField} size={size} />}
    </FormHookField>
  );
};

interface _FieldProps
  extends Pick<Props, 'formField' | 'size'>,
    IAmFormHookField {}

const _Field: FC<_FieldProps> = ({ hook, formField, size }) => {
  const rawAddress = hook.field.value;
  const name = hook.field.name;

  const addressValue = useMemo(
    () => asJsonOrEmpty(rawAddress) as DynamicFormAddress,
    [rawAddress]
  );

  const allowedCountries: string[] =
    formField.getConfigProperty<AddressFieldConfig>('AllowedCountries') ||
    (!formField.properties.AllowedCountry
      ? []
      : [formField.properties.AllowedCountry]);

  const handlePropertyChange = useEvent(
    (key: KeyOf<DynamicFormAddress>, value: string) => {
      const updated = { ...addressValue };
      //Clear the readable text from the google place_id as we are changing the address
      updated.text = '';

      if (value) {
        updated[key] = value;
      } else {
        delete updated[key];
      }

      hook.setFieldValue(JSON.stringify(updated));
      hook.trigger(hook.field.name);

      return;
    }
  );

  const resolveRequiredError = (key: KeyOf<DynamicFormAddress>) => {
    if (!hook.showError) {
      return undefined;
    }

    if (!(addressValue[key] || '').trim()) {
      return 'This field is required';
    }

    return undefined;
  };

  const handleSuggestionSelect = useEvent(
    (value: Partial<DynamicFormAddress>) => {
      const updated: Partial<DynamicFormAddress> = {
        streetAddress: value.streetAddress,
        ...value
      };

      if (allowedCountries.length === 1) {
        updated.country = allowedCountries[0];
      }

      hook.setFieldValue(
        JSON.stringify({
          streetAddress: value.streetAddress,
          ...value
        })
      );
      hook.trigger(hook.field.name);
    }
  );

  const { displayLabel, displayPlaceholder, isRequired } = formField;
  const disabled = hook.disabled;

  const hasLabel = !!displayLabel;

  return (
    <Fieldset
      className={classes.root}
      disabled={disabled}
      legend={
        hasLabel ? (
          <FormInputLabel required={isRequired} size={size}>
            {displayLabel}
          </FormInputLabel>
        ) : undefined
      }
    >
      <Stack>
        <StreetAddressField
          placeholder={displayPlaceholder}
          size={size}
          value={addressValue.streetAddress || ''}
          fieldName={name}
          disabled={disabled}
          onChange={(v) => handlePropertyChange('streetAddress', v)}
          onSuggestionSelect={handleSuggestionSelect}
          error={resolveRequiredError('streetAddress')}
          required={isRequired}
        />
        <SubPremiseField
          size={size}
          disabled={disabled}
          value={addressValue.subpremise || ''}
          fieldName={name}
          onChange={(v) => handlePropertyChange('subpremise', v)}
        />
        <CityField
          size={size}
          disabled={disabled}
          value={addressValue.city || ''}
          fieldName={name}
          onChange={(v) => handlePropertyChange('city', v)}
          error={resolveRequiredError('city')}
          required={isRequired}
        />
        <Group justify={'space-between'}>
          <div>
            <StateField
              size={size}
              disabled={disabled}
              value={addressValue.state || ''}
              fieldName={name}
              onChange={(v) => handlePropertyChange('state', v)}
              error={resolveRequiredError('state')}
              required={isRequired}
            />
          </div>
          <div>
            <PostCodeField
              size={size}
              disabled={disabled}
              value={addressValue.postal_code || ''}
              fieldName={name}
              onChange={(v) => handlePropertyChange('postal_code', v)}
              error={resolveRequiredError('postal_code')}
              required={isRequired}
            />
          </div>
        </Group>
        <CountryField
          size={size}
          disabled={disabled}
          value={addressValue.country || ''}
          fieldName={name}
          onChange={(v) => handlePropertyChange('country', v)}
          error={resolveRequiredError('country')}
          required={isRequired}
        />
      </Stack>
    </Fieldset>
  );
};

const asJsonOrEmpty = (value?: string) => {
  try {
    return value ? JSON.parse(value) : {};
  } catch {
    return {};
  }
};
