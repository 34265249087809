import { Combobox, ComboboxProps, InputBase, useCombobox } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { forwardRef } from 'react';

import { ChatQuestionIcon } from '@/common/components/Icons/ChatQuestionIcon';
import { useFlag } from '@komo-tech/core/hooks/useFlag';

import {
  resolveAddressFromPlace,
  useAddressSuggestions
} from './_useAddressSuggestions';
import {
  DynamicFormAddress,
  resolveFieldName
} from './DynamicFieldAddress.types';

interface Props extends ComboboxProps {
  placeholder: string;
  required?: boolean;
  error?: string;
  onSuggestionSelect: (address: Partial<DynamicFormAddress>) => void;
  onChange: (value: string) => void;
  value: string;
  allowedCountries?: string[];
  fieldName: string;
}

export const StreetAddressField = forwardRef<HTMLInputElement, Props>(
  (
    {
      placeholder,
      fieldName,
      error,
      required,
      onSuggestionSelect,
      onChange,
      disabled,
      size,
      value,
      allowedCountries,
      ...rest
    },
    ref
  ) => {
    const showSearch = useFlag(false);
    const [debouncedSearch] = useDebouncedValue(value, 400);

    const [suggestionsLookup, lastSuccessful] = useAddressSuggestions({
      search: debouncedSearch,
      disabled: !showSearch.value,
      allowedCountries
    });

    let rawOptions = suggestionsLookup[debouncedSearch];
    if (!rawOptions && lastSuccessful) {
      rawOptions = suggestionsLookup[lastSuccessful];
    }
    const options = (rawOptions || []).map((item) => (
      <Combobox.Option value={item.value} key={item.value}>
        {item.label}
      </Combobox.Option>
    ));

    const hasSearch = !!(value || '').trim();
    const combobox = useCombobox({
      opened: showSearch.value && hasSearch && (options || []).length > 0,
      onDropdownClose: () => combobox.resetSelectedOption()
    });

    return (
      <Combobox
        store={combobox}
        withinPortal={false}
        onOptionSubmit={(placeId) => {
          resolveAddressFromPlace(placeId, onSuggestionSelect);
          showSearch.setFalse();
        }}
        disabled={disabled}
        size={size}
        position="bottom"
        {...rest}
      >
        <Combobox.Target>
          <InputBase
            size={size}
            disabled={disabled}
            ref={ref}
            id={resolveFieldName(fieldName, 'streetAddress')}
            name={resolveFieldName(fieldName, 'streetAddress')}
            label="Street address"
            error={error}
            withAsterisk={required}
            autoComplete="street-line1"
            value={value}
            onChange={(event) => {
              showSearch.setTrue();
              combobox.updateSelectedOptionIndex();
              onChange(event.currentTarget.value);
            }}
            onClick={() => showSearch.setTrue()}
            onFocus={() => showSearch.setTrue()}
            onBlur={() => {
              showSearch.setFalse();
            }}
            placeholder={placeholder}
            rightSectionPointerEvents="none"
          />
        </Combobox.Target>
        <Combobox.Dropdown>
          <Combobox.Header
            fw="500"
            bg="gray.1"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <ChatQuestionIcon size="sm" mr="0.35rem" />
            Suggestions
          </Combobox.Header>
          <Combobox.Options>{options}</Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    );
  }
);
