import { FC } from 'react';

import { Box } from '@/common/components/Display/Box';
import { SkeletonV2 } from '@/common/components/Skeleton';
import { Text } from '@/common/components/Typography/Text';
import { useMeasure } from '@komo-tech/core/hooks/useMeasure';

import classes from './QrCodeReader.module.css';
import { QrCodeReaderProps } from './QrCodeReader.types';

export const QrCodeReaderDrawerSkeleton: FC<
  Pick<QrCodeReaderProps, 'qrBorderColor' | 'infoContent'>
> = ({ qrBorderColor = 'var(--mantine-color-text)', infoContent }) => {
  const container = useMeasure();
  const hasInfoContent = !!infoContent;

  return (
    <Box className={classes.root} ref={container.register}>
      <Box
        __vars={{
          '--qr-border-c': qrBorderColor
        }}
        className={classes.camera}
      >
        <SkeletonV2 className={classes.cameraSkeleton} />
      </Box>
      {hasInfoContent && (
        <SkeletonV2>
          <Text ta="center" mt="xl">
            {infoContent}
          </Text>
        </SkeletonV2>
      )}
    </Box>
  );
};
