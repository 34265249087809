import isNil from 'lodash/isNil';

import { IAmFormHookField } from '../../FormHookField';
import {
  FormTimeZonePicker,
  FormTimeZonePickerProps
} from './FormTimeZonePicker';

export interface FormHookTimeZonePickerProps
  extends IAmFormHookField,
    Omit<
      FormTimeZonePickerProps,
      'required' | 'name' | 'value' | 'defaultValue' | 'error' | 'disabled'
    > {}

export const FormHookTimeZonePicker = (props: FormHookTimeZonePickerProps) => {
  const { hook, onBlur, onChange, ...rest } = props;
  return (
    <FormTimeZonePicker
      {...rest}
      required={hook.required}
      name={hook.field.name}
      ref={hook.field.ref}
      disabled={hook.disabled}
      onBlur={(e) => {
        hook.field.onBlur();
        onBlur?.(e);
      }}
      onChange={(e, o) => {
        hook.field.onChange(e);
        onChange?.(e, o);
      }}
      value={!isNil(hook.field.value) ? hook.field.value : ''}
      error={hook.showError ? hook.errorMessage : undefined}
    />
  );
};
