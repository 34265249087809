import cx from 'clsx';
import format from 'date-fns/format';
import { BaseSyntheticEvent, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { BlockLoader } from '@/common/components/BlockLoader';
import { Box } from '@/common/components/Display/Box';
import { Stack } from '@/common/components/Display/Stack';
import {
  MobileValue,
  resolveMobileValue
} from '@/common/components/Form/FormMobileInput';
import { Loader } from '@/common/components/Loader/Loader';
import { useActionHandler } from '@/common/hooks/useActionHandler';
import { useConstant } from '@komo-tech/core/hooks/useConstant';
import { useEvent } from '@komo-tech/core/hooks/useEvent';
import { useOnMount } from '@komo-tech/core/hooks/useOnMount';
import { FormField } from '@/common/models/form/FormField';
import { FormSubmissionResult } from '@/common/models/form/FormFieldErrors';
import { FieldTypes } from '@/common/models/form/FormFieldTypes';
import { Guid } from '@komo-tech/core/models/Guid';
import {
  getLocaleDateString,
  parseStringDate,
  utcDateOrUndefined
} from '@komo-tech/core/utils/date';
import { SessionStorageHelper } from '@komo-tech/core/utils/sessionStorage';

import { Form } from '../../Form';
import { FormHiddenSubmit } from '../../FormHiddenSubmit';
import classes from './DynamicFormRenderer.module.css';
import { DynamicFormRendererBanner } from './DynamicFormRendererBanner';
import { DynamicFormRendererButtons } from './DynamicFormRendererButtons';
import { DynamicFormRendererField } from './DynamicFormRendererField';
import { DynamicFormRendererHeader } from './DynamicFormRendererHeader';
import {
  ControlledDynamicFormRendererProps,
  DynamicFormRendererProps
} from './DynamicFormRendererTypes';
import { OptionalTurnstileField } from './OptionalTurnstileField';
import { OptionalReceiptUploadField } from './ReceiptUploadField';
import { useDynamicFormRendererSizes } from './useDynamicFormRendererSizes';

export function DynamicFormRenderer<
  TSuccess extends FormSubmissionResult = FormSubmissionResult
>({
  formId,
  turnstileProps,
  receiptValidatorDefinition,
  fields,
  populateDefaultValue,
  banner,
  header,
  submitButtonDisabled,
  submitButtonHidden,
  submitButton,
  onSubmitAsync,
  onSuccess,
  onError,
  disabled: disabledProp = false,
  noToastOnError = false,
  extraButtons = [],
  children,
  onInit,
  extraValues,
  ...rest
}: DynamicFormRendererProps<TSuccess>) {
  const defaultValues = useDynamicFormRendererDefaultValues({
    fields,
    populateDefaultValue: populateDefaultValue ?? populateFieldDefault,
    extraValues: extraValues
  });

  const formMethods = useForm<Record<string, any>>({
    mode: 'all',
    defaultValues
  });

  return (
    <ControlledDynamicFormRenderer<TSuccess>
      fields={fields}
      banner={banner}
      header={header}
      submitButtonDisabled={submitButtonDisabled}
      submitButtonHidden={submitButtonHidden}
      submitButton={submitButton}
      onSubmitAsync={onSubmitAsync}
      onSuccess={onSuccess}
      onError={onError}
      disabled={disabledProp}
      noToastOnError={noToastOnError}
      extraButtons={extraButtons}
      formMethods={formMethods}
      onInit={onInit}
      receiptValidatorDefinition={receiptValidatorDefinition}
      formId={formId}
      turnstileProps={turnstileProps}
      {...rest}
    >
      {children}
    </ControlledDynamicFormRenderer>
  );
}

export function ControlledDynamicFormRenderer<
  TSuccess extends FormSubmissionResult = FormSubmissionResult
>({
  formId,
  fields = [],
  banner,
  header,
  receiptValidatorDefinition,
  submitButtonDisabled,
  submitButtonHidden,
  submitButton,
  onSubmitAsync,
  onSuccess,
  onError,
  disabled: disabledProp = false,
  noToastOnError = false,
  extraButtons = [],
  children,
  onInit,
  onScrollToElement,
  formMethods,
  turnstileProps,
  isSubmitting: isSubmittingProp,
  tryLegacyAutoSubmit,
  tryAutoSubmit,
  onAutoSubmitResolve,
  className,
  emailProps,
  ...rest
}: ControlledDynamicFormRendererProps<TSuccess>) {
  const hasRunInit = useRef(false);
  const hasTurnstile = !!turnstileProps;
  const [handleAsync, { isHandling }] = useActionHandler();
  const disabled = disabledProp || isHandling || isSubmittingProp;
  const isSubmitting = isHandling || isSubmittingProp;

  const handleSubmit = useEvent(
    (e?: BaseSyntheticEvent<object, any, any>, onSettled?: () => void) => {
      if (turnstileProps && !turnstileProps?.token) {
        console.warn('No turnstile token found');
        return;
      }

      formMethods.handleSubmit((v: Record<string, any>) => {
        // hack to trigger refresh of turnstile token on entry, easy way to handle error response case
        turnstileProps?.triggerRefresh?.();
        const sanitized = sanitizeDynamicFormRendererFormValues(v, fields);
        return handleAsync(
          () => onSubmitAsync(sanitized, turnstileProps?.token),
          {
            onSuccess: (r) => {
              if (!!r?.formFieldErrors?.length) {
                r.formFieldErrors.forEach((f) => {
                  if (f?.errors?.length) {
                    formMethods.setError(`root.${f.fieldName}`, {
                      type: 'temp',
                      message: f.errors.join('. ')
                    });
                  }
                });
              }
              onSuccess?.(r);
            },
            onError,
            onSettled,
            noToastOnError
          }
        );
      })(e);
    }
  );

  const { isAutoSubmitting, isCheckingAutoSubmit } = _useLegacyTryAutoSubmit({
    fields,
    tryLegacyAutoSubmit,
    onResolved: onAutoSubmitResolve,
    handleSubmit,
    turnstileProps
  });

  const { autoSubmittingState } = useTryAutoSubmit({
    defaultValues: formMethods.formState.defaultValues,
    fields: fields,
    tryAutoSubmit,
    onResolved: onAutoSubmitResolve,
    handleSubmit,
    turnstileProps
  });

  if (!hasRunInit.current) {
    hasRunInit.current = true;
    onInit?.({
      triggerSubmit: () => handleSubmit()
    });
  }

  const {
    contentPadding,
    fieldSize,
    headerFieldSpacing,
    headerSpacing,
    isMobile
  } = useDynamicFormRendererSizes();

  const renderHeader = () => (
    <DynamicFormRendererHeader
      spacing={headerSpacing}
      title={header?.title}
      subTitle={header?.subTitle}
      ta={header.ta}
    />
  );

  const customEmailRender =
    emailProps?.type === 'custom' ? emailProps?.render : undefined;

  const renderFields = () => (
    <Stack
      style={{
        flex: '1 1 auto',
        // performance fix for iOS - force it to use GPU
        transform: 'translate3d(0,0,0)'
      }}
    >
      <OptionalReceiptUploadField
        disabled={disabled}
        definition={receiptValidatorDefinition}
        formId={formId}
        size={fieldSize}
      />
      {fields.map((f, i) =>
        f.isEmailField && !!customEmailRender ? (
          customEmailRender({
            field: f,
            formMethods,
            disabled,
            fieldSize,
            headerFieldSpacing
          })
        ) : (
          <DynamicFormRendererField
            key={f.id.toString()}
            onOptionSelect={() => {
              const isLastField = i === fields.length - 1;
              const scrollElementId = isLastField
                ? `${formId}-submit`
                : fields[i + 1].id.toString();
              onScrollToElement?.(scrollElementId);
            }}
            formField={f}
            disabled={disabled}
            size={fieldSize}
          />
        )
      )}
      <OptionalTurnstileField turnstileProps={turnstileProps} />
    </Stack>
  );

  const renderButtons = () => (
    <DynamicFormRendererButtons
      disabled={disabled}
      formId={formId}
      isSubmitting={isSubmitting}
      submitButton={submitButton}
      submitButtonDisabled={
        submitButtonDisabled || (hasTurnstile && !turnstileProps.isVerified)
      }
      submitButtonHidden={submitButtonHidden}
      extraButtons={extraButtons}
      size={fieldSize}
    />
  );

  const hasRenderProps = !!children;

  if (autoSubmittingState !== null) {
    return (
      <Box h="80svh">
        <Loader size="3rem" />
      </Box>
    );
  }
  return (
    <BlockLoader
      size="3rem"
      isLoading={isAutoSubmitting}
      opacity={0}
      containerProps={{
        className: cx(className, classes.root),
        ...rest
      }}
    >
      <FormProvider {...formMethods}>
        <Form
          mih={isCheckingAutoSubmit ? 300 : undefined}
          data-no-opacity={isCheckingAutoSubmit ? 'true' : 'false'}
          gap={0}
          w="100%"
          onSubmit={handleSubmit}
        >
          <FormHiddenSubmit />
          <DynamicFormRendererBanner image={banner} />
          <Stack p={contentPadding} gap={0}>
            {hasRenderProps ? (
              children({
                disabled,
                formMethods,
                isMobile,
                fieldSize,
                fieldsData: fields,
                headerFieldSpacing,
                isSubmitting: isHandling,
                submit: () => handleSubmit(),
                renderFields,
                renderHeader,
                renderButtons
              })
            ) : (
              <Stack gap="md" style={{ flex: '1 1 auto' }}>
                <Stack gap={headerFieldSpacing}>
                  {renderHeader()}
                  {renderFields()}
                </Stack>
                {renderButtons()}
              </Stack>
            )}
          </Stack>
        </Form>
      </FormProvider>
    </BlockLoader>
  );
}

interface UseDynamicFormRendererDefaultValuesProps
  extends Pick<DynamicFormRendererProps, 'fields' | 'populateDefaultValue'> {
  extraValues?: Record<string, any>;
}
export const useDynamicFormRenderer = ({
  fields,
  populateDefaultValue,
  extraValues
}: UseDynamicFormRendererDefaultValuesProps) => {
  const defaultValues = useDynamicFormRendererDefaultValues({
    fields,
    populateDefaultValue,
    extraValues
  });

  return useForm<Record<string, any>>({
    mode: 'all',
    defaultValues
  });
};

const populateFieldDefault = (f: FormField) => f.isActive;

const useDynamicFormRendererDefaultValues = ({
  fields,
  populateDefaultValue,
  extraValues
}: UseDynamicFormRendererDefaultValuesProps) => {
  return useConstant(() => {
    const defaultValues: Record<string, any> = {};
    if (extraValues?.email) {
      defaultValues['email'] = extraValues.email;
    }
    fields.filter(populateDefaultValue ?? populateFieldDefault).forEach((f) => {
      let defaultVal = f.getDefaultValue();

      if (!f.isHiddenField) {
        defaultVal = extraValues?.[f.name] ?? defaultVal;
      }

      if (!!defaultVal) {
        defaultValues[f.name] = sanitizeForDynamicFormValue(defaultVal, f);
      }
    });
    //Just ensuring email is set if provided
    if (!defaultValues['email']) {
      const emailStorageValue = SessionStorageHelper.getItem('email');
      if (emailStorageValue) {
        defaultValues['email'] = emailStorageValue;
      }
    }
    return defaultValues;
  });
};

export const sanitizeDynamicFormRendererFormValues = (
  values: Record<string, any>,
  fields: FormField[]
) => {
  const mapped = { ...values };

  fields.forEach((x) => {
    if (mapped[x.name] !== null && mapped[x.name] !== undefined) {
      switch (x.type) {
        case FieldTypes.Phone:
          if (mapped[x.name]) {
            const phoneValue = mapped[x.name] as MobileValue;
            mapped[x.name] = `${phoneValue.dialCode} ${phoneValue.phone}`;
          }
          break;

        case FieldTypes.Number:
        case FieldTypes.Currency:
        case FieldTypes.Percentage:
          mapped[x.name] = mapped[x.name].toString();
          break;
        case FieldTypes.Birthday:
        case FieldTypes.Date:
          mapped[x.name] = parseStringDate(mapped[x.name]);
          break;
      }
    }
    if (
      mapped[x.name] === undefined ||
      mapped[x.name] === null ||
      mapped[x.name] === ''
    ) {
      if (x.type === FieldTypes.Optin) {
        mapped[x.name] = false;
      } else {
        delete mapped[x.name];
      }
    }
  });

  return mapped;
};

export const sanitizeForDynamicFormValue = (
  rawValue: string,
  field?: FormField
) => {
  if (!field.type) {
    return rawValue;
  }
  switch (field.type) {
    case FieldTypes.Phone:
      return resolveMobileValue(
        rawValue,
        field.getConfigProperty('AllowedCountries') ?? []
      );
    case FieldTypes.Option:
    case FieldTypes.Dropdown:
      if (Guid.isValid(rawValue)) {
        return rawValue;
      } else {
        return (field.displayOptions || [])
          .find((x) => x.value === rawValue)
          ?.id?.toString?.();
      }
    case FieldTypes.Birthday:
    case FieldTypes.Date:
      try {
        return format(utcDateOrUndefined(rawValue), getLocaleDateString());
      } catch (e) {
        console.error('Error formatting date', e, rawValue);
        return rawValue;
      }
    default:
      return rawValue;
  }
};

interface TryLegacyAutoSubmitOptions
  extends Pick<
    ControlledDynamicFormRendererProps,
    'turnstileProps' | 'fields' | 'tryLegacyAutoSubmit'
  > {
  onResolved?: () => void;
  handleSubmit: (
    e?: BaseSyntheticEvent<object, any, any>,
    onSettled?: () => void
  ) => void;
}
const _useLegacyTryAutoSubmit = ({
  handleSubmit,
  turnstileProps,
  fields,
  tryLegacyAutoSubmit: tryAutoSubmit = false,
  onResolved
}: TryLegacyAutoSubmitOptions) => {
  const [isCheckingAutoSubmit, setIsCheckingAutoSubmit] =
    useState(tryAutoSubmit);
  const [isAutoSubmitting, setIsAutoSubmitting] = useState(false);

  const hasTurnstile = !!turnstileProps;
  useOnMount(() => {
    if (!isCheckingAutoSubmit) {
      return;
    }

    if (!!(fields || []).length) {
      setIsCheckingAutoSubmit(false);
      onResolved?.();
      return;
    }

    setIsAutoSubmitting(true);
    if (hasTurnstile) {
      setTimeout(() => {
        handleSubmit(undefined, () => {
          setTimeout(() => {
            setIsCheckingAutoSubmit(false);
            setIsAutoSubmitting(false);
            onResolved?.();
          }, 1500);
        });
      }, 3000);
    } else {
      handleSubmit(undefined, () => {
        setTimeout(() => {
          setIsCheckingAutoSubmit(false);
          setIsAutoSubmitting(false);
          onResolved?.();
        }, 1500);
      });
    }
  });

  return {
    isAutoSubmitting,
    isCheckingAutoSubmit
  };
};

interface TryAutoSubmitOptions
  extends Pick<
    ControlledDynamicFormRendererProps,
    'turnstileProps' | 'tryAutoSubmit'
  > {
  fields: FormField[];
  defaultValues: { [x: string]: any };
  onResolved?: () => void;
  handleSubmit: (
    e?: BaseSyntheticEvent<object, any, any>,
    onSettled?: () => void
  ) => void;
}
const useTryAutoSubmit = ({
  handleSubmit,
  onResolved,
  turnstileProps,
  fields,
  defaultValues,

  tryAutoSubmit = false
}: TryAutoSubmitOptions) => {
  const [autoSubmittingState, setAutoSubmittingState] = useState<
    'checking' | 'submitting' | null
  >(tryAutoSubmit ? 'checking' : null);
  const hasTurnstile = !!turnstileProps;
  useOnMount(() => {
    if (!tryAutoSubmit) {
      return;
    }

    const formValid = fields.every(
      (f) => !f.isRequired || !!defaultValues[f.name]
    );
    if (!formValid) {
      setAutoSubmittingState(null);
      onResolved?.();
      return;
    }

    setAutoSubmittingState('submitting');
    if (hasTurnstile) {
      setTimeout(() => {
        handleSubmit(undefined, () => {
          setTimeout(() => {
            setAutoSubmittingState(null);
            onResolved?.();
          }, 1500);
        });
      }, 3000);
    } else {
      handleSubmit(undefined, () => {
        setTimeout(() => {
          setAutoSubmittingState(null);
          onResolved?.();
        }, 1500);
      });
    }
  });

  return {
    autoSubmittingState
  };
};
