import { FCC } from 'fcc';
import { memo, useMemo, useState } from 'react';

import { Guid } from '@komo-tech/core/models/Guid';
import { useFrontSite } from '@/front/components/site/SiteHost/Store';
import { useSiteCache } from '@/front/utils/SiteCache';

import { SignalRProvider } from '../SignalRProvider';
import { GroupName, HubGroupScopes, SiteHubPath } from '../types';

interface Props {
  disabled?: boolean;
}

export const SiteHubSignalRProvider: FCC<Props> = memo(
  ({ disabled: disabledProp = false, children }) => {
    const [userIds, setUserIds] = useState<Guid[]>([]);
    const siteIdString = useFrontSite((x) => x.site.id.toString());
    const isBot = useFrontSite((x) => x.clientRequestInfo.isBot);

    const userIdString = useSiteCache((x) => x.userId);
    const groupNames = useMemo<GroupName[]>(() => {
      const groups = [
        {
          Scope: HubGroupScopes.Site,
          GroupId: siteIdString
        },
        {
          Scope: HubGroupScopes.UserSession,
          GroupId: userIdString
        }
      ];
      (userIds || []).forEach((id) => {
        if (!id.equals(userIdString)) {
          groups.push({
            Scope: HubGroupScopes.UserSession,
            GroupId: id.toString()
          });
        }
      });
      return groups;
    }, [siteIdString, userIdString, userIds]);

    const disabled = disabledProp || isBot;

    return (
      <SignalRProvider
        signalRHubName={SiteHubPath}
        signalRGroupNames={groupNames}
        disabled={disabled}
        onSetUserIds={setUserIds}
      >
        {children}
      </SignalRProvider>
    );
  }
);
