import { useLocalTime } from '@komo-tech/core/hooks/useLocalTime';
import { formatInTimeZone } from 'date-fns-tz';
import { FC } from 'react';

import { Box } from '@/common/components/Display/Box';

interface Props {
  timeZone: string;
  variant?: 'default' | 'unstyled';
}
export const LocalTime: FC<Props> = ({ timeZone, variant = 'default' }) => {
  const [date, formattedDate] = useLocalTime(timeZone);
  const text = `Local time for ${timeZone} is ${formattedDate} ${formatInTimeZone(
    date,
    timeZone,
    'h:mm a'
  )}`;

  if (variant === 'unstyled') {
    return <span>{text}</span>;
  }

  return <Box c="dimmed">{text}</Box>;
};
