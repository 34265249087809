import { mapArray } from '@komo-tech/core/utils/array';

export class CompetitionEntryOptions {
  requiredFields: string[];

  constructor(props?: Partial<CompetitionEntryOptions>) {
    props = props || {};
    Object.assign(this, props);
    this.requiredFields = mapArray(props.requiredFields, (x) => x);
  }
}
