import { useOnMount } from '@komo-tech/core/hooks/useOnMount';
import { NoOp } from '@komo-tech/core/utils/NoOp';
import { FCC } from 'fcc';
import { FC, ReactNode, useState } from 'react';
import {
  createContext,
  useContext,
  useContextSelector
} from 'use-context-selector';

import { SiteUser } from '@/common/models/SiteUser';
import { DataDogServiceHelper } from '@/common/utils/DataDogService';
import { FrontSessionStore } from '@/front/components/site/user-session-v2/FrontSessionStore';
import { SiteCache } from '@/front/utils/SiteCache';

export interface UserContextState {
  user: SiteUser;
  setUser: (user: SiteUser) => void;
}

export const UserContext = createContext<UserContextState>({
  user: undefined,
  setUser: NoOp
});

UserContext.displayName = 'UserContext';

interface ControlledProps extends Props {
  onSetUser: (user: SiteUser) => void;
}

export const ControlledUserProvider: FCC<ControlledProps> = ({
  user,
  onSetUser,
  children
}) => {
  useOnMount(() => {
    if (user?.userId) {
      SiteCache.getState().setUserId(user.userId);
    }
  });

  return (
    <UserContext.Provider
      value={{
        user: user,
        setUser: (user) => {
          if (user?.userId) {
            SiteCache.getState().setUserId(user.userId);
            DataDogServiceHelper.trySetUserInformation(user);
          }
          onSetUser(user);
        }
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

interface Props {
  user: SiteUser;
  children: ReactNode;
}

export const UserProvider: FC<Props> = ({ user, children }) => {
  const [currentUser, setCurrentUser] = useState(user);

  return (
    <UserContext.Provider
      value={{
        user: currentUser,
        setUser: (user) => {
          if (user?.userId) {
            SiteCache.getState().setUserId(user.userId);
            DataDogServiceHelper.trySetUserInformation(user);
          }
          setCurrentUser(new SiteUser(user));
          FrontSessionStore.getState().setSessionV2Id(user?.userSessionV2Id);
        }
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): SiteUser => {
  return useUserContextSelector((s) => s.user);
};

export const useUserContext = (): UserContextState => {
  const context = useContext(UserContext);

  //TODO OLD REACT CONTEXT RETURNS THE INITIAL CONTEXT NOT UNDEFINED
  //However code relies on this at the moment. Needs to be looked at and enforced properly
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};

export function useUserContextSelector<T>(
  selector: (value: UserContextState) => T
): T {
  const selected = useContextSelector(UserContext, (state) => {
    if (state === undefined) {
      throw new Error('useUserContext must be used within a UserProvider');
    }
    return selector(state);
  });
  return selected;
}

export const useUnsafeUserContext = (): UserContextState | undefined => {
  return useContext(UserContext);
};

export function useUnsafeUserContextSelector<T>(
  selector: (value?: UserContextState) => T
): T {
  return useContextSelector(UserContext, (state) => {
    if (state === undefined) {
      return undefined;
    }
    return selector(state);
  });
}

export const useUnsafeUser = (): SiteUser | undefined => {
  return useUnsafeUserContextSelector((s) => s.user);
};
