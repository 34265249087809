import { FC, HTMLAttributes, ReactNode } from 'react';

import { Button, ButtonProps } from '@/common/components/Button';
import { Box } from '@/common/components/Display/Box';
import { FlexV2 } from '@/common/components/Display/Flex';
import { Group } from '@/common/components/Display/Group';
import { NoOp } from '@komo-tech/core/utils/NoOp';

import classes from './ModalFooter.module.css';
import { ModalSizeVariantProps, ModalStyler } from './ModalStyles';

export interface BaseModalButtonProps
  extends Pick<
    ButtonProps,
    | 'onClick'
    | 'disabled'
    | 'busy'
    | 'variant'
    | 'showSkeleton'
    | 'tooltip'
    | 'leftSection'
  > {
  text: ReactNode;
}

export interface ModalFooterActionButtonsProps {
  primaryButton: BaseModalButtonProps;
  extraButtons?: BaseModalButtonProps[];
}

export const ModalFooterActionButtons: FC<ModalFooterActionButtonsProps> = ({
  primaryButton,
  extraButtons
}) => {
  const hasExtraButtons = !!extraButtons?.length;

  const {
    text: primaryText,
    variant: primaryVariant,
    ...primaryRest
  } = primaryButton;

  return (
    <FlexV2 direction="row-reverse" gap="sm">
      <Button
        onClick={NoOp}
        variant={primaryVariant}
        overflowHidden
        {...primaryRest}
      >
        {primaryText}
      </Button>
      {hasExtraButtons &&
        extraButtons.map((b, index) => {
          const { text, variant = 'subtle', ...rest } = b;
          return (
            <Button key={index} variant={variant} overflowHidden {...rest}>
              {text}
            </Button>
          );
        })}
    </FlexV2>
  );
};

interface Props extends HTMLAttributes<HTMLDivElement>, ModalSizeVariantProps {
  fixed?: boolean;
  spacedChildren?: boolean;
  children: ReactNode;
}

export function ModalFooter({
  fixed,
  spacedChildren,
  children,
  sizeVariant,
  ...rest
}: Props) {
  const dto = ModalStyler.sizeData(sizeVariant);

  return fixed ? (
    <>
      <Box h={'4rem'} />
      <Group
        gap={0}
        className={classes.fixed}
        p={`${dto.paddingY} ${dto.paddingXOutside}`}
        {...rest}
      >
        {children}
      </Group>
    </>
  ) : (
    <Group
      gap={0}
      p={`${dto.paddingY} ${dto.paddingXOutside}`}
      className={classes.footer}
      {...rest}
      data-has-spaced-children={spacedChildren}
    >
      {children}
    </Group>
  );
}

ModalFooter.Buttons = ModalFooterActionButtons;
