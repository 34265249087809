import { useMemo } from 'react';

import { AssetTypes } from '@/common/models/AssetTypes';
import { FormField } from '@/common/models/form/FormField';
import { FormFieldProperties } from '@/common/models/form/FormFieldProperties';
import { FieldTypes } from '@/common/models/form/FormFieldTypes';
import { Guid } from '@komo-tech/core/models/Guid';

import { ReceiptUploaderFieldName } from './ReceiptUploaderFieldName';

interface Props {
  enabled: boolean;
  label?: string;
  formId: Guid;
}

const acceptedFiles = [AssetTypes.Image, AssetTypes.Pdf];
const allowedFileTypesJson = JSON.stringify(acceptedFiles);

export const useReceiptUploaderFormField = ({
  enabled,
  label,
  formId
}: Props): FormField | null => {
  return useMemo(
    () =>
      enabled
        ? new FormField({
            name: ReceiptUploaderFieldName,
            isRequired: true,
            type: FieldTypes.File,
            label: label || 'Please upload your receipt',
            properties: new FormFieldProperties({
              FileLimit: 1,
              AllowedFileTypes: allowedFileTypesJson
            }),
            order: -2,
            isActive: true,
            formId: formId
          })
        : null,
    [formId, label, enabled]
  );
};
