import { useOnMount } from '@komo-tech/core/hooks/useOnMount';
import { useUnsafeEmbeddedHostContextSelector } from '@/front/components/embedded/EmbeddedHostProvider';
import { EmbeddedClientMessageTypes } from '@/front/components/embedded/EmbeddedMessageTypes';

/**
 * If we are in a Komo-embed, hide the outer modal header on the host page
 * while this component is mounted.
 */
export const useHideEmbedHostHeaderOnMount = () => {
  const setHideHeaderCount = useUnsafeEmbeddedHostContextSelector(
    (s) => s.setHideHeaderCount
  );
  const sendMessage = useUnsafeEmbeddedHostContextSelector(
    (s) => s.sendMessage
  );

  // increment a counter to allow for multiple layers of modals
  useOnMount(
    ({ trackHeadCount }) => {
      trackHeadCount();
    },
    {
      resolveContextData: () => {
        const hasFunc = !!setHideHeaderCount;
        return {
          trackHeadCount: () => {
            if (hasFunc) {
              setHideHeaderCount((s) => s + 1);
            }
          },
          cleanup: () => {
            if (hasFunc) {
              setHideHeaderCount((s) => {
                const x = s - 1;
                if (x < 0) {
                  return 0;
                }
                return x;
              });
            }
          }
        };
      },
      cleanup: ({ cleanup }) => {
        cleanup();
      }
    }
  );

  // short circuit - handle navigating away or opening an image from within an embed iframe
  useOnMount(
    ({ handleUnload }) => {
      window.addEventListener('beforeunload', handleUnload);
    },
    {
      resolveContextData: () => {
        return {
          handleUnload: () => {
            if (!window || !sendMessage) {
              return;
            }
            sendMessage({
              message: EmbeddedClientMessageTypes.ShowHeader,
              showHeader: true
            });
          }
        };
      },
      cleanup: ({ handleUnload }) => {
        window.removeEventListener('beforeunload', handleUnload);
      }
    }
  );
};
