import { FieldValues } from 'react-hook-form';

import isNil from 'lodash/isNil';

import { IAmFormHookField } from '../FormHookField';
import { FormPasswordInput, FormPasswordInputProps } from './FormPasswordInput';

export interface FormHookPasswordInputProps<
  TFieldValues extends FieldValues = FieldValues
> extends IAmFormHookField<TFieldValues>,
    Omit<
      FormPasswordInputProps,
      'required' | 'name' | 'value' | 'defaultValue' | 'error' | 'disabled'
    > {}

export function FormHookPasswordInput<
  TFieldValues extends FieldValues = FieldValues
>(props: FormHookPasswordInputProps<TFieldValues>) {
  const { hook, onBlur, onChange, ...rest } = props;
  return (
    <FormPasswordInput
      {...rest}
      required={hook.required}
      name={hook.field.name}
      ref={hook.field.ref}
      disabled={hook.disabled}
      onBlur={(e) => {
        hook.field.onBlur();
        onBlur?.(e);
      }}
      onChange={(e, v) => {
        hook.field.onChange(v);
        onChange?.(e, v);
      }}
      value={!isNil(hook.field.value) ? hook.field.value : ''}
      error={hook.showError ? hook.errorMessage : undefined}
    />
  );
}
