import { produce } from 'immer';
import { createStore, useStore } from 'zustand';

import { VisibleCard } from '@/common/components/Card/shared/VisibilityTracking/types';
import { Guid } from '@komo-tech/core/models/Guid';
import { ActivitySlice } from '@/common/models/user-sessions-v2/Front/ActivitySlice';
import { ZustandComputed } from '@/common/utils/State/ZuStandComputedMiddleware';

import {
  computeState,
  debounceFocusedCardChanges,
  handleCardVisibilityChange,
  handleModalLayerChange,
  handleSessionChange,
  prepareForCollection,
  prepareForCollectionPost,
  resetAfterFailedCollection,
  updateActivity
} from './_actions';
import {
  CardModalLayer,
  IFrontSessionStore,
  IFrontSessionStoreComputed
} from './_types';

export const FrontSessionStore = createStore<
  IFrontSessionStore,
  [['komo/zustand-computed', IFrontSessionStoreComputed]]
>(
  ZustandComputed(
    (set, get) => ({
      session: null,
      lastCollectionAt: null,
      sessionHistory: [],
      cardModalLayers: [],
      visibleCards: [],
      currentSpan: null,
      activitySlices: [],
      setSessionV2Id: (
        sessionV2Id?: Guid,
        date?: Date,
        endActivity?: boolean
      ) =>
        set(
          produce<IFrontSessionStore>((state: IFrontSessionStore) => {
            handleSessionChange(state, sessionV2Id);
            // update activity even if session didn't change, to ensure span slices are populated
            updateActivity(state, date || new Date(), endActivity);
          })
        ),
      updateCardVisibility: (isVisible: boolean, visibleCard: VisibleCard) =>
        set(
          produce<IFrontSessionStore>((state: IFrontSessionStore) => {
            handleCardVisibilityChange(state, isVisible, visibleCard);
            debounceFocusedCardChanges(
              state,
              handleUpdateFocusedCardId,
              debounceTimeMs
            );
          })
        ),
      updateCardModalLayer: (
        isVisible: boolean,
        layer: Omit<CardModalLayer, 'startedAt'>
      ) =>
        set(
          produce<IFrontSessionStore>((state: IFrontSessionStore) => {
            handleModalLayerChange(state, isVisible, new Date(), layer);
            debounceFocusedCardChanges(
              state,
              handleUpdateFocusedCardId,
              debounceTimeMs
            );
          })
        ),
      _updateFocusedCardId: () =>
        set(
          produce<IFrontSessionStore>((state: IFrontSessionStore) => {
            state.focusedCardId = state._candidateFocusedCardId;
            updateActivity(state, new Date());
          })
        ),
      prepareForCollection: (date: Date) => {
        // end slices if needed
        set(
          produce<IFrontSessionStore>((state: IFrontSessionStore) => {
            prepareForCollection(state, date);
          })
        );

        // pull out prepared slices
        const activitySlices = get().activitySlices;

        // set slices to empty to avoid double collection
        set(
          produce<IFrontSessionStore>((state: IFrontSessionStore) => {
            prepareForCollectionPost(state);
          })
        );

        return activitySlices;
      },
      resetAfterFailedCollection: (activitySlices: ActivitySlice[]) =>
        set(
          produce<IFrontSessionStore>((state: IFrontSessionStore) => {
            resetAfterFailedCollection(state, activitySlices);
          })
        )
    }),
    computeState
  )
);

export function useFrontSession<T = unknown>(
  selector: (state: IFrontSessionStore & IFrontSessionStoreComputed) => T
) {
  return useStore(FrontSessionStore, selector);
}

// This callback is here to avoid circular references between store and actions.
// It is called via a timeout, so we get the latest state from the store.
const handleUpdateFocusedCardId = () =>
  FrontSessionStore.getState()._updateFocusedCardId();

const debounceTimeMs = 1_000;
