import {
  Checkbox as MantineCheckbox,
  CheckboxProps as MantineCheckboxProps
} from '@mantine/core';
import { FC, forwardRef, isValidElement, ReactNode } from 'react';

import { Tooltip, TooltipProps } from '@/common/components/Tooltip';
import { useEvent } from '@komo-tech/core/hooks/useEvent';
import { useFlag } from '@komo-tech/core/hooks/useFlag';
import { ForwardRefWithStaticComponents } from '@/common/utils/ForwardRefWithStaticComponents';

import classes from './FormCheckbox.module.css';

export type CheckboxLabelType =
  | {
      type: 'Content';
      content: ReactNode;
      tooltip?: Omit<TooltipProps, 'children'>;
    }
  | {
      type: 'Html';
      html: string;
    };

export interface FormCheckboxProps extends Omit<MantineCheckboxProps, 'label'> {
  label?: CheckboxLabelType;
  ref?: any;
  withAnimation?: boolean;
}

type FormCheckboxV2Component = ForwardRefWithStaticComponents<
  FormCheckboxProps,
  { Group: typeof MantineCheckbox.Group }
>;

export const FormCheckbox: FormCheckboxV2Component = forwardRef<
  HTMLInputElement,
  FormCheckboxProps
>(({ label, mod: modProp, onChange, withAnimation, id, ...rest }, ref) => {
  //This is to control the jelly animation from not
  //being triggered on the first render
  const hasChangedOnce = useFlag();
  const mod = modProp || {};
  mod['changed'] = hasChangedOnce.value;
  mod['animated'] = withAnimation;
  const handleChange = useEvent<FormCheckboxProps['onChange']>((e) => {
    hasChangedOnce.setTrue();
    onChange?.(e);
  });

  return (
    <MantineCheckbox
      {...rest}
      label={<CheckboxLabel label={label} />}
      ref={ref}
      mod={mod}
      onChange={handleChange}
    />
  );
}) as any;

FormCheckbox.Group = MantineCheckbox.Group;

const CheckboxLabel: FC<Pick<FormCheckboxProps, 'label'>> = ({ label }) => {
  if (!label) {
    return null;
  }

  if (label.type === 'Content') {
    const hasTooltip = !!label?.tooltip?.label;

    return (
      <>
        {hasTooltip ? (
          <Tooltip {...(label.tooltip || {})}>
            {isValidElement(label.content) ? (
              label.content
            ) : (
              <span>{label.content}</span>
            )}
          </Tooltip>
        ) : (
          label.content
        )}
      </>
    );
  }

  return (
    <span
      className={classes.label}
      dangerouslySetInnerHTML={{ __html: label.html }}
    />
  );
};
