import { ParsedUrlQuery } from 'querystring';

import { useConstant } from '@komo-tech/core/hooks/useConstant';
import { Dictionary } from '@komo-tech/core/models/Dictionary';

export const useInitialQueryParams = ({ query }: { query: ParsedUrlQuery }) => {
  return useConstant(() => {
    const result: Dictionary<string> = {};
    if (!query) return result;
    Object.keys(query).forEach((x) => {
      const entry = query[x];
      if (typeof entry === 'string') {
        result[x] = entry;
      } else {
        result[x] = entry?.join(',');
      }
    });
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });
};
