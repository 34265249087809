import isNil from 'lodash/isNil';
import { useCallback } from 'react';
import { FieldValues } from 'react-hook-form';

import { useForkRef } from '@komo-tech/core/hooks/useForkRef';

import { IAmFormHookField } from '../FormHookField';
import { FormTextInput, FormTextInputProps } from './FormTextInput';

export interface FormHookTextInputProps<TFieldValues extends FieldValues = any>
  extends IAmFormHookField<TFieldValues>,
    Omit<
      FormTextInputProps,
      'required' | 'name' | 'value' | 'defaultValue' | 'error' | 'disabled'
    > {
  onInit?: (element: HTMLInputElement) => void;
}

export function FormHookTextInput<TFieldValues extends FieldValues = any>(
  props: FormHookTextInputProps<TFieldValues>
) {
  const { hook, onBlur, onChange, onInit, ...rest } = props;

  const handleInit = useCallback((element: HTMLInputElement) => {
    onInit?.(element);
  }, []);

  const ref = useForkRef(handleInit, hook.field.ref);
  return (
    <FormTextInput
      {...rest}
      required={hook.required}
      name={hook.field.name}
      ref={ref}
      disabled={hook.disabled}
      onBlur={(e, v) => {
        hook.field.onBlur();
        onBlur?.(e, v);
      }}
      onChange={(e, v) => {
        hook.field.onChange(v);
        onChange?.(e, v);
      }}
      value={!isNil(hook.field.value) ? hook.field.value : ''}
      error={hook.showError ? hook.errorMessage : undefined}
    />
  );
}
