import isString from 'lodash/isString';
import dynamic from 'next/dynamic';
import React, { FC } from 'react';

import { Center } from '@/common/components/Display/Center';
import { Stack } from '@/common/components/Display/Stack';
import { Drawer, DrawerHeader } from '@/common/components/Drawer';
import { QRCodeIcon } from '@/common/components/Icons/QRCodeIcon';
import { Title } from '@/common/components/Typography/Title';

import { QrCodeReaderDrawerSkeleton } from '../QrCodeReader.Skeleton';
import { QrCodeReaderModalProps } from './QrCodeReaderModal.types';

const qrBorderColor = '#9484b3';
const QrCodeReader = dynamic(
  () => import('../QrCodeReader').then((x) => x.QrCodeReader),
  {
    loading: () => <QrCodeReaderDrawerSkeleton qrBorderColor={qrBorderColor} />,
    ssr: false
  }
);

export const QrCodeReaderDrawer: FC<QrCodeReaderModalProps> = ({
  onClose,
  onCheckAsync,
  onIsValidCallback,
  onError,
  opened,
  title = 'Scan QR Code',
  ...rest
}) => {
  const titleIsString = isString(title);

  return (
    <Drawer.Root
      zIndex={1101}
      opened={opened}
      onClose={onClose}
      position={'bottom'}
      transitionProps={{ duration: 400 }}
    >
      <Drawer.Overlay />
      <Drawer.Content
        style={{ borderRadius: '12px 12px 0 0' }}
        mx="auto"
        maw={450}
        h="90%"
      >
        <Stack h="100%" p={0} gap={0}>
          <Drawer.Header p="0.5rem" mih="5rem">
            <DrawerHeader
              noBottomBorder
              title={
                <Center w="100%">
                  <Center
                    w={64}
                    h={64}
                    p={'md'}
                    bg={'#ccbbec'}
                    style={{ borderRadius: '8px' }}
                  >
                    <QRCodeIcon size={32} color={'#000000'} />
                  </Center>
                </Center>
              }
              onClose={onClose}
              closeButtonStyle={{
                bgColor: '#FFFFFF',
                color: '#000000'
              }}
            />
          </Drawer.Header>
          <Drawer.Body>
            <Stack p="1.5rem 1rem" gap="md">
              {titleIsString ? (
                <Title order={3} ta="center">
                  {title}
                </Title>
              ) : (
                title
              )}
              <QrCodeReader
                {...rest}
                onCheckAsync={onCheckAsync}
                onError={onError}
                qrBorderColor={qrBorderColor}
                onIsValidCallback={onIsValidCallback}
                errorProps={{
                  message:
                    'We will need access to your camera to scan the QR code, make sure you have it enabled in your settings.'
                }}
              />
            </Stack>
          </Drawer.Body>
        </Stack>
      </Drawer.Content>
    </Drawer.Root>
  );
};
